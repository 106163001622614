import React from "react"

import Nav from "../components/nav"
import Content from "../components/content"
import StyledA from "../components/styleda"
import Card from "../components/card"

import slinkyLogo from "./img/slinky-logo.png"
import scalapyLogo from "./img/scalapy-logo.png"
import genalgoLogo from "./img/genalgo-logo.png"
import projectEuler from "./img/project-euler.jpg"
import apiExplorer from "./img/apiExplorerScreenshot.png"
import helloCollidium from "./img/hello-collidium.jpg"
import { PageTitle } from "../components/titles"

// from https://github.com/simple-icons/simple-icons
const githubIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" height="75" viewBox="0 0 24 24" width="75" style={{
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  }}>
    <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"/>
  </svg>
);

const playIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" height="75" viewBox="0 0 24 24" width="75" style={{
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  }}>
    <path d="M8 5v14l11-7z"/>
    <path d="M0 0h24v24H0z" fill="none"/>
  </svg>
);

const projects = [
  {
    image: slinkyLogo,
    icon: githubIcon,
    name: "Slinky",
    description: (
      <p style={{marginTop: 0}}>A library for writing React applications using Scala.js, a Scala to JavaScript compiler. Features strong type-safety for creating HTML trees and a strightforward API that mirrors React's ES6 API.</p>
    ),
    url: "https://slinky.dev"
  },
  {
    image: scalapyLogo,
    icon: githubIcon,
    name: "ScalaPy",
    description: (
      <p style={{marginTop: 0}}>An interop layer for using Python libraries in Scala applications. Allows developers to maintain type safety by creating type definitions for Python libraries and supports compilation to native code for efficient interop.</p>
    ),
    url: "https://scalapy.dev"
  },
  {
    image: genalgoLogo,
    icon: githubIcon,
    name: "Genalgo",
    description: (
      <p style={{marginTop: 0}}>A bioinformatics library for Scala (and Scala.js) that lets you treat biological data as standard collections with memory-efficient underlying data structures.</p>
    ),
    url: "https://github.com/shadaj/genalgo"
  },
  {
    image: projectEuler,
    icon: playIcon,
    name: "Project Euler",
    description: (
      <p style={{marginTop: 0}}>Solutions to various Project Euler problems in Scala, demonstrating the use of functional programming concepts to implement algorithms</p>
    ),
    url: "https://www.youtube.com/watch?v=mUft6wl6Xrg&list=PLo_ebKiwsNTpJmp47_lQJYuKqk6XVjf2a"
  },
  {
    image: apiExplorer,
    icon: githubIcon,
    name: "AutoSchema",
    description: (
      <p style={{marginTop: 0}}>An open-source library for automatically generating JSON Schema from Scala classes. Created during my internship at Coursera.</p>
    ),
    url: "https://github.com/coursera/autoschema"
  },
  {
    image: helloCollidium,
    icon: playIcon,
    name: "Hello Collidium",
    description: (
      <p style={{marginTop: 0}}>A YouTube video series where I build up an HTML5 physics-based game from scratch using Scala.js</p>
    ),
    url: "https://www.youtube.com/watch?v=3zCXmerO5cY&list=PLo_ebKiwsNTpNezm0cY1cRhGkUKrnnTq9"
  }
]

export default class Projects extends React.Component {
  render() {
    return (
      <div>
        <Nav current="projects"/>
        <Content>
          <PageTitle>I've built lots of projects</PageTitle>
          <h2 style={{
            fontFamily: "'PT Serif', serif",
            fontSize: 35,
            fontWeight: 700,
            marginTop: 10,
            marginBottom: 20
          }}>See even more on <StyledA href="https://github.com/shadaj">GitHub</StyledA>!</h2>
          <div style={{
            display: "flex",
            flexWrap: "wrap"
          }}>
            {projects.map((work, i) => {
              return (
                <Card
                  key={i}
                  right={i % 2 !== 0}
                  icon={work.icon}
                  image={work.image}
                  name={work.name}
                  title={""}
                  description={work.description} url={work.url}
                />
              );
            })}
          </div>
        </Content>
      </div>
    )
  }
}
